class Quiz {
  constructor() {
    let cache = {};
    let currentQuestion = 0;
    const totalQuestions = 5;
    let answerRevealed = false;

    let initFunc = () => {
      initCache();
      registerEvents();
      showInitialQuestion();
    };

    let initCache = () => {
      cache.questions = document.querySelectorAll(".speech-text");
      cache.answers = document.querySelectorAll(".answers-wrapper");
      cache.nbrDisplay = document.querySelector(".nbr");
      cache.playButton = document.querySelector("#play-button");
      cache.nextButton = document.querySelector("#next-question");
      cache.backButton = document.querySelector("#back-button");
      cache.certificate = document.querySelector(".certificate");
    };

    let registerEvents = () => {
      cache.playButton.addEventListener("click", startQuiz);
      cache.nextButton.addEventListener("click", goToNextQuestion);
      cache.backButton.addEventListener("click", goToPreviousQuestion);

      cache.answers.forEach((wrapper) => {
        wrapper.addEventListener("click", handleAnswerClick);
      });
    };

    // Initialisation du quiz
    const showInitialQuestion = () => {
      const initialQuestion = document.querySelector(
        '.speech-text[data-started="0"]'
      );
      if (initialQuestion) {
        initialQuestion.classList.add("active");
      }
      cache.nextButton.style.display = "none";
      cache.backButton.style.display = "none";
    };

    const startQuiz = () => {
      cache.playButton.style.display = "none";
      cache.nextButton.style.display = "none";
      cache.backButton.style.display = "none";
      currentQuestion = 1;
      showQuestion(currentQuestion);
      cache.nbrDisplay.textContent = `${currentQuestion}/${totalQuestions}`;
    };

    // Afficher les bons textes en fonctions de l'avancement du quiz
    const showQuestion = (index) => {
      cache.questions.forEach((question) =>
        question.classList.remove("active")
      );
      cache.answers.forEach((answer) => answer.classList.remove("active"));

      const question = document.querySelector(
        `.speech-text[data-question="${index}"]`
      );
      const answer = document.querySelector(
        `.answers-wrapper[data-question="${index}"]`
      );

      if (question && answer) {
        question.classList.add("active");
        answer.classList.add("active");
      }
      cache.nextButton.style.display = "none";
      cache.backButton.style.display = index > 1 ? "flex" : "none";
    };

    // Fonctionnement principal des valdations des réponses
    const handleAnswerClick = (event) => {
      const answer = event.target.closest(".question");
      const currentAnswersWrapper = document.querySelector(
        `.answers-wrapper[data-question="${currentQuestion}"]`
      );
      if (answer && currentAnswersWrapper) {
        if (!answerRevealed) {
          // Révéler toutes les réponses
          currentAnswersWrapper
            .querySelectorAll(".question")
            .forEach((answer) => {
              const isCorrect = answer.getAttribute("data-correct") === "true";
              answer.classList.add(isCorrect ? "true" : "false");
            });
          answerRevealed = true;

          // Désactive le clic sur une réponse déjà sélectionnée
          currentAnswersWrapper.classList.add("disabled");

          const response = document.querySelector(
            `.speech-text[data-reponse="${currentQuestion}"]`
          );
          if (response) {
            cache.questions.forEach((question) =>
              question.classList.remove("active")
            );
            response.classList.add("active");
          }

          if (currentQuestion === totalQuestions) {
            cache.nextButton.removeEventListener("click", goToNextQuestion);
            cache.nextButton.addEventListener("click", goToValidate);
          }
          cache.nextButton.style.display = "flex";
        } else {
          // Passer à la question suivante
          answerRevealed = false;
          currentQuestion++;
          if (currentQuestion <= totalQuestions) {
            showQuestion(currentQuestion);
          }
        }
      }
    };

    // Aller a la prochaine question
    const goToNextQuestion = () => {
      answerRevealed = false;
      resetAnswerClasses(currentQuestion);
      currentQuestion++;
      if (currentQuestion <= totalQuestions) {
        showQuestion(currentQuestion);
        cache.nbrDisplay.textContent = `${currentQuestion}/5`;

        // Activer les clics sur les réponses pour la nouvelle question
        const newAnswersWrapper = document.querySelector(
          `.answers-wrapper[data-question="${currentQuestion}"]`
        );
        if (newAnswersWrapper) {
          newAnswersWrapper.classList.remove("disabled");
        }
      }
    };

    // Aller a la question précédente
    const goToPreviousQuestion = () => {
      if (answerRevealed) {
        // Si une réponse a été révélée, revenir à la question actuelle mais sans les réponses révélées
        answerRevealed = false;
        resetAnswerClasses(currentQuestion);
      } else if (currentQuestion > 1) {
        // Si aucune réponse n'a été révélée, revenir à la question précédente
        resetAnswerClasses(currentQuestion);
        currentQuestion--;
        showQuestion(currentQuestion);
        cache.nbrDisplay.textContent = `${currentQuestion}/${totalQuestions}`;

        const newAnswersWrapper = document.querySelector(`.answers-wrapper[data-question="${currentQuestion}"]`);
        if (newAnswersWrapper) {
          newAnswersWrapper.classList.remove("disabled");
        }
      }
    };

    // Afficher le texte du certificat
    const goToValidate = () => {
      cache.answers.forEach((answer) => answer.classList.remove("active"));

      const finalResponse = document.querySelector(
        `.speech-text[data-question="6"]`
      );
      if (finalResponse) {
        cache.questions.forEach((question) =>
          question.classList.remove("active")
        );
        finalResponse.classList.add("active");
        cache.certificate.classList.add("active");
        cache.nextButton.style.display = "none";
        cache.backButton.style.display = "none";
      }
    };

    const resetAnswerClasses = (index) => {
      const answersWrapper = document.querySelector(
        `.answers-wrapper[data-question="${index}"]`
      );
      if (answersWrapper) {
        answersWrapper.querySelectorAll(".question").forEach((answer) => {
          answer.classList.remove("true", "false");
        });
        answersWrapper.classList.remove("disabled");
      }
    };

    initFunc();
  }
}

export { Quiz };
