import gsap from '../libs/gsap'
import SplitType from '../libs/split-type'

class Text {
  constructor() {
    let cache = {};

    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.textes = document.querySelectorAll(".split-text");
    };

    let registerEvents = () => {
      cache.textes.forEach((line) => {
        const text = new SplitType(line, { types: "words" });
        observeActiveClass(line, text.words);
      });
    };

    let observeActiveClass = (line, words) => {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.attributeName === "class" &&
            line.classList.contains("active")
          ) {
            animateText(words);
          }
        });
      });

      observer.observe(line, { attributes: true });
    };

    let animateText = (words) => {
      gsap.fromTo(
        words,
        { opacity: 0 },
        { opacity: 1, stagger: 0.05, duration: 0.4, ease: "power2.out" }
      );
    };

    initFunc();
  }
}

export { Text };
