import { Quiz } from './components/quiz'
import { Text } from './components/text'
import { Bandeau } from './utils/helpers/bandeau'

class App {
  constructor() {
    if(document.querySelector(".quiz")) {
      const quiz = new Quiz();
      const text = new Text();
    }
    if(document.querySelector(".section2-follow-us")) {
      const bandeau = new Bandeau();
    }
  }
}

window.onload = function() {
  let app = new App();
};